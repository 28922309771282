<template>
    <layout>
        <div>
            <div class="d-flex justify-content-between">
                <h5>Manage Items</h5>
                <div class="mt-15 d-flex justify-content-between">
                    <input v-model="search" @keyup="getList()" class="form-control form-control-sm mr-10" placeholder="Search">
                    <a data-toggle="modal" data-target="add-new-item-modal" role="button" class="btn btn-primary btn-sm">
                        <i class="fa fa-plus"></i>&nbsp; Add New
                    </a>
                </div>
            </div>
            <div>
                <table class="table table-hover table-outer-bordered font-size-12">
                    <thead>
                        <tr>
                            <th width="20%">🔨 NAME</th>
                            <th width="30%">📜 DETAILS</th>
                            <th class="text-center" width="15%">📏 SIZE</th>
                            <th class="text-center" width="15%">📜 LIMITER</th>
                            <th width="20%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in data.data" :key="item.id">
                            <td>{{ item.name }}</td>
                            <td>{{ item.details }}</td>
                            <td class="text-center">{{ item.size }}</td>
                            <td class="text-center">{{ item.limiter }}</td>
                            <td class="text-right font-size-12">
                                <edit-button class="mr-5" :id="item.id" v-on:editable-data="updateEditableItem"></edit-button>
                                <delete-button :id="item.id" v-on:deleted="getList"></delete-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex justify-content-end">
                    <pagination
                        v-if="data.links && data.links.length > 3"
                        :links="data.links"
                        :first_page_url="data.first_page_url"
                        :last_page_url="data.last_page_url"
                        :prev_page_url="data.prev_page_url"
                        :next_page_url="data.next_page_url"
                        v-on:click="getList"
                    ></pagination>
                </div>
            </div>
            <add-modal id="add-new-item-modal" title="Add New Item" v-on:added="getList"></add-modal>
            <edit-modal id="edit-item-modal" title="Edit Item" :data="editData" v-on:updated="getList"></edit-modal>
        </div>
    </layout>
</template>

<script>
import Pagination from '../../components/Pagination';
import AddModal from "./AddModal";
import EditModal from "./EditModal";
import DeleteButton from "./DeleteButton";
import EditButton from "./EditButton";

export default {
    components: {
        Pagination,
        AddModal,
        EditModal,
        DeleteButton,
        EditButton
    },
    data() {
        return {
            data: [],
            search: '',
            editData: {},
        }
    },
    created() {
        this.getList();
    },
    methods: {
        getList(link = null) {
            let newLink = link || `api/items?page=1`;
            this.$http.get(`${newLink}&search=${this.search}`).then((response) => {
                this.data = response.data.list;
            });
        },
        updateEditableItem(data) {
            this.editData = data;
        }
    }
}
</script>