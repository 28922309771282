<template>
    <modal>
        <form @submit.prevent="checkForm" method="post" class="w-400 mw-full">
            <div class="form-group">
                <label for="name" class="required">Name</label>
                <div class="invalid-feedback" v-if="errors.name">
                    <ul>
                        <li>This field is required.</li>
                    </ul>
                </div>
                <input v-model="form.name" type="text" class="form-control" name="name" placeholder="Name">
            </div>
            <div class="form-group">
                <label for="details" class="required">Details</label>
                <div class="invalid-feedback" v-if="errors.details">
                    <ul>
                        <li>This field is required.</li>
                    </ul>
                </div>
                <input v-model="form.details" type="text" class="form-control" name="details" placeholder="Details">
            </div>
            <div class="form-group">
                <label for="size" class="required">Size</label>
                <div class="invalid-feedback" v-if="errors.size">
                    <ul>
                        <li>This field is required.</li>
                    </ul>
                </div>
                <input v-model="form.size" type="text" class="form-control" name="size" placeholder="Size">
            </div>
            <div class="form-group">
                <label for="limiter">Limiter</label>
                <input v-model="form.limiter" type="number" class="form-control" id="limiter" placeholder="Limiter">
            </div>
            <div class="text-right mt-20">
                <a data-dismiss="modal" class="btn mr-5" role="button">Close</a>
                <button type="submit" class="btn btn-primary" role="button">Submit</button>
            </div>
        </form>
    </modal>
</template>

<script>
import Modal from "../../components/Modal";
import Toast from "../../mixins/Toast";
import * as halfmoon from 'halfmoon';

export default {
    name: 'edit-modal',
    components: { Modal },
    mixins: [ Toast ],
    props: [ 'data' ],
    emits: [ 'updated' ],
    data() {
        return {
            id: 0,
            errors: {
                name: false,
                details: false,
                size: false
            },
            form: {
                name: '',
                details: '',
                size: '',
                limiter: '',
            }
        }
    },
    updated() {
            this.id = this.data.id;
            this.form.name = this.data.name;
            this.form.details = this.data.details;
            this.form.size = this.data.size;
            this.form.limiter = this.data.limiter;
            halfmoon.toggleModal('edit-item-modal');
    },
    methods: {
        submitForm() {
            this.$http.post(`api/item/update/${this.id}`, this.form).then((response) => {
                this.ToastSuccess(response.data);
                halfmoon.toggleModal('edit-item-modal');
                this.resetForm();
                this.$emit('updated');
            });
        },
        checkForm: function () {
            if (this.form.name != '' && this.form.details != '' && this.form.size != '') {
                this.submitForm();
                return;
            }

            this.resetErrors();

            if (!this.form.name) {
                this.errors.name = true;
            }
            if (!this.form.details) {
                this.errors.details = true;
            }
            if (!this.form.size) {
                this.errors.size = true;
            }
        },
        resetForm() {
            this.form.name = '';
            this.form.details = '';
            this.form.size = '';
            this.form.limiter = '';
        },
        resetErrors() {
            this.errors.name = false;
            this.errors.details = false;
            this.errors.size = false;
        },
    }
}
</script>