<template>
    <a @click="edit" class="btn btn-sm btn-secondary"><i class="fa fa-pencil-alt"></i></a>
</template>

<script>
export default {
    name: 'edit-button',
    props: [ 'id' ],
    emits: [ 'editable-data' ],
    methods: {
        edit() {
            this.$http.get(`api/item/get/${this.id}`).then((response) => {
                this.$emit('editable-data', response.data);
            });
        },
    }
}
</script>